<script lang="ts">
  import { Loader } from "google-maps";

  const loader = new Loader("AIzaSyB4kPS6MUOFOaqEkzDlfOH2pf2PnYtf6l8");
  import isEqual from "lodash/isEqual";

  import { createInfoWindowHTML } from "./createInfoWindowHTML";

  import {
    selectedLocation,
    mapCenterLatLng,
    zoomToLocation,
    isMapFullScreen,
    allowMarkerBounce,
  } from "../../store/main";
  import { sortedLocations } from "../../store/sortedLocations";

  let container;
  let googleMapApi;
  let map;
  let markers = [];
  let infoWindows = [];
  let previousMapLocations = [];

  const removeAllMarkers = async () => {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    markers = [];
  };

  const zoomOnCenterMap = async (lat, lng, zoom) => {
    if (!googleMapApi) {
      return;
    }
    map.zoom = zoom;
    map.setCenter({
      lat,
      lng,
    });
  };

  const checkLocationDuplicity = () => {
    // deep object compare
    if (isEqual(previousMapLocations, $sortedLocations)) return true;
    // ref assign to prevMapLoc
    previousMapLocations = $sortedLocations;
  };

  const renderMap = async () => {
    if (!googleMapApi) {
      await mapInit();
    }

    if (checkLocationDuplicity()) return;
    await removeAllMarkers();

    if ($sortedLocations && $sortedLocations.length) {
      $sortedLocations.forEach(location => addMarker(location));
      if ($zoomToLocation) {
        await zoomOnCenterMap($mapCenterLatLng.lat, $mapCenterLatLng.lng, 10);
      }
    }
  };

  $: $sortedLocations && renderMap();
  $: $selectedLocation && bounceCurrentLocation();

  const updateMapCenterInStore = () => {
    const currentCenter = map.getCenter();
    const latLngCenter = {
      lat: currentCenter.lat(),
      lng: currentCenter.lng(),
    };
    $mapCenterLatLng = latLngCenter;
  };

  const triggerMapUpdate = () => {
    $zoomToLocation = false;
    updateMapCenterInStore();
  };

  const bounceOnce = aMarker => {
    if (aMarker.getAnimation() !== null) {
      aMarker.setAnimation(null);
    } else {
      aMarker.setAnimation(google.maps.Animation.BOUNCE);
    }
    setTimeout(() => {
      aMarker.setAnimation(null);
    }, 200);
  };

  const bounceCurrentLocation = () => {
    let currentLatLocation = $selectedLocation["latitude"];
    let currentLngLocation = $selectedLocation["longitude"];
    currentLatLocation &&
      markers.forEach(marker => {
        if (
          currentLatLocation === marker.position.lat() &&
          currentLngLocation === marker.position.lng() &&
          $allowMarkerBounce
        ) {
          bounceOnce(marker);
        }
      });
  };

  const addMarker = props => {
    const mapMarkerBlueIcon = {
      path: "M13.4375 39.2188C14.1406 40.3125 15.7812 40.3125 16.4844 39.2188C27.8906 22.8125 30 21.0938 30 15C30 6.71875 23.2812 0 15 0C6.64062 0 0 6.71875 0 15C0 21.0938 2.03125 22.8125 13.4375 39.2188ZM15 21.25C11.4844 21.25 8.75 18.5156 8.75 15C8.75 11.5625 11.4844 8.75 15 8.75C18.4375 8.75 21.25 11.5625 21.25 15C21.25 18.5156 18.4375 21.25 15 21.25Z",
      fillColor: "#65A6C2",
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 0.9,
    };

    const mapMarkerBlackIcon = {
      path: "M13.4375 39.2188C14.1406 40.3125 15.7812 40.3125 16.4844 39.2188C27.8906 22.8125 30 21.0938 30 15C30 6.71875 23.2812 0 15 0C6.64062 0 0 6.71875 0 15C0 21.0938 2.03125 22.8125 13.4375 39.2188ZM15 21.25C11.4844 21.25 8.75 18.5156 8.75 15C8.75 11.5625 11.4844 8.75 15 8.75C18.4375 8.75 21.25 11.5625 21.25 15C21.25 18.5156 18.4375 21.25 15 21.25Z",
      fillColor: "#383C50",
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 0.9,
    };

    const marker = new googleMapApi.Marker({
      position: {
        lat: props.latitude,
        lng: props.longitude,
      },
      map: map,
      animation: google.maps.Animation.DROP,
      icon: mapMarkerBlueIcon,
    });

    markers.push(marker);

    if (props.placement_provider_name) {
      const infoWindow = new googleMapApi.InfoWindow({
        content: createInfoWindowHTML(props),
      });
      infoWindows.push(infoWindow);
      infoWindow.addListener("domready", () => {
        const infoWin = document.getElementsByClassName("pp_info_window")[0];
        const infoWinCloseButton = document.querySelector(
          "#pp_info_window_content button",
        );
        infoWinCloseButton.addEventListener("click", e => {
          e.stopPropagation();
          infoWindows.forEach(win => win.close());
          markers.forEach(mk => {
            mk.setIcon(mapMarkerBlueIcon);
          });
        });
        infoWin.addEventListener("click", () => {
          $sortedLocations.filter(location => {
            if (location.id == $selectedLocation.id) {
              window.location.href = location.url;
            }
          });
        });
      });
      marker.addListener("click", () => {
        $allowMarkerBounce = false;
        markers.forEach(mk => {
          mk.setIcon(mapMarkerBlueIcon);
        });
        marker.setIcon(mapMarkerBlackIcon);
        infoWindows.forEach(win => win.close());
        infoWindow.open(map, marker);
        $selectedLocation = { ...$selectedLocation, id: props.id };
      });
    }
  };

  const mapInit = async () =>
    loader.load().then(function (google) {
      const centerOnUK = { lat: 53.0, lng: -1.7 };
      const zoomCenterOfUK = 7;
      googleMapApi = google.maps;
      map = new google.maps.Map(container, {
        center: centerOnUK,
        zoom: zoomCenterOfUK,
        mapTypeControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        scaleControl: true,
        streetViewControl: false,
        fullscreenControl: false,
      });

      // map.addListener("dragend", () => {
      //   $zoomToLocation = false;
      //   updateMapCenterInStore();
      // });
    });

  mapInit();
</script>

<div
  class={$isMapFullScreen
    ? "w-full h-googleFullScreen google-map relative"
    : "w-full h-full google-map relative"}
>
  <div class="h-full" bind:this={container}></div>
  <button
    on:click={triggerMapUpdate}
    class="absolute top-28 right-1 form-title rounded-full py-1 px-15px py-10px mx-3 text-white font-medium"
    >Update</button
  >
</div>

<style>
  :global(#pp_info_window_content) {
    max-width: 287px !important;
    width: 287px;
    cursor: pointer;
  }

  :global(.pp_info_window--image) {
    width: 100%;
    height: 180px;
  }

  :global(.gm-style-iw) {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }

  :global(.gm-style-iw-d) {
    padding-right: 0 !important;
    overflow: hidden !important;
  }

  :global(.gm-ui-hover-effect) {
    display: none !important;
  }
</style>
