<script>
  import Carousel from "@beyonk/svelte-carousel";
  import Icon from "svelte-awesome";
  import { chevronLeft, chevronRight } from "svelte-awesome/icons";
  import { favouriteList } from "../../store/favourites/favouriteList";

  import { Circle2 } from "svelte-loading-spinners";
  import { isFavHandlerReady } from "../../store/favourites/isFavHandlerReady";
  import { addFavouritesApi } from "../../utils/addFavouritesApi";
  import { removeFavouritesApi } from "../../utils/removeFavouritesApi";

  import { doesHttpOnlyCookieExist } from "../../utils/doesHttpOnlyCookieExist";

  export let id;

  let totalImages = [1, 2, 3];

  const handleHeartClick = id => {
    if (!doesHttpOnlyCookieExist("sessionid")) return;
    $isFavHandlerReady = false;
    if ($favouriteList.includes(id)) {
      // Is Fav - Call Remove
      removeFavouritesApi(id);
    } else {
      // is Not Fav - call Add
      addFavouritesApi(id);
    }
  };
</script>

<div class="w-full md:w-64 mt-4 z-0">
  <Carousel perPage={1}>
    <span class="control chevronLeft invisible md:visible" slot="left-control">
      <Icon data={chevronLeft} />
    </span>
    {#each totalImages as anImage}
      <div class="slide-content">
        <img
          class="w-full"
          src="https://loremflickr.com/320/240/dog"
          alt={`Kitten ${anImage}`}
        />
      </div>
    {/each}
    <span
      class="control chevronRight invisible md:visible"
      slot="right-control"
    >
      <Icon data={chevronRight} />
    </span>
  </Carousel>
  {#if $isFavHandlerReady}
    <span
      class="relative bottom-200px md:bottom-180px left-4 z-5"
      on:click={() => handleHeartClick(id)}
    >
      {#if $favouriteList && $favouriteList.includes(id)}
        <i class="fas fa-heart" style=" font-size:1.3rem; color: #fe6f5e" />
      {:else}
        <i class="fas fa-heart" style=" font-size:1.3rem; color: #fff" />
      {/if}
    </span>
  {:else}
    <div class="relative bottom-200px md:bottom-180px left-4 z-6 w-5 h-5">
      <Circle2 size="24" unit="px" />
    </div>
  {/if}
</div>

<style>
  .chevronRight :global(svg) {
    width: 50%;
    height: 50%;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 32px;
    padding: 4px;
    position: relative;
    left: 35px;
  }

  .chevronLeft :global(svg) {
    width: 50%;
    height: 50%;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 32px;
    padding: 4px;
    position: relative;
    right: 30px;
  }

  .control:hover :global(svg) {
    transform: scale(1.3);
    transition-duration: 500ms;
  }
</style>
